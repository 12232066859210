/** @jsx jsx */
import { useContext, useEffect, useState } from 'react'
import { jsx, Text, Heading, Card, Box, Button } from 'theme-ui'
import Layout from '../components/layout'
import Wrapper from '../components/layout/wrapper'
import Inner from '../components/layout/inner'
import { Stack } from 'raam'
import StoreContext from '../contexts/storeContext'
import { LineItem } from '../components/shop'
import { responsive } from '../utils/helpers'
import { Link } from 'gatsby'
import MetaData from '../components/metadata'

const Cart = props => {
  const { store } = useContext(StoreContext)
  const [empty, setEmpty] = useState(true)
  const checkout = store.checkout

  const handleCheckout = () => {
    window.location.assign(checkout.webUrl)
  }

  const totalSX = {
    textAlign: responsive('left', 'right'),
    pt: 4,
  }

  const subHeadingSX = {
    variant: 'text.headingsmall',
    color: 'black',
    mb: 2,
  }

  useEffect(() => {
    setEmpty(!checkout.lineItems.length)
  }, [checkout])

  return (
    <Layout {...props} noPromo>
      <MetaData title="Cart" />
      <Wrapper>
        <Inner>
          <Box py="100px">
            <Heading as="h1" sx={{ color: 'black' }}>
              Cart
            </Heading>
            <Card mt={3}>
              {empty && <Text as="p">Your cart is currently empty.</Text>}
              {!empty && (
                <Stack gap={3}>
                  {Object.keys(checkout.lineItems).map((x, i) => {
                    const item = checkout.lineItems[i]
                    if (item) {
                      return (
                        <LineItem
                          {...item}
                          key={i}
                          last={i === checkout.lineItems.length - 1}
                        />
                      )
                    }
                  })}
                </Stack>
              )}
            </Card>
            {!empty && (
              <Box sx={totalSX}>
                <Heading as="h3" sx={subHeadingSX}>
                  Subtotal
                </Heading>
                <Text as="p" mb={6}>
                  £ {checkout.subtotalPrice.amount}
                </Text>
                <Heading as="h3" sx={subHeadingSX}>
                  Taxes
                </Heading>
                <Text as="p" mb={6}>
                  £ {checkout.totalTax.amount}
                </Text>
                <Heading as="h3" sx={subHeadingSX}>
                  Total
                </Heading>
                <Text as="p" mb={6}>
                  £ {checkout.totalPrice.amount}
                </Text>
                <Button
                  onClick={handleCheckout}
                  disabled={checkout.lineItems.length === 0}
                >
                  Check out
                </Button>
              </Box>
            )}
            <Box sx={totalSX}>
              <Button as={Link} to="/shop" variant="tertiary">
                Continue Shopping
              </Button>
            </Box>
          </Box>
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export default Cart
